import {
  Box,
  Chip,
  Grid,
  ImageList,
  ImageListItem,
  Pagination,
  PaginationItem,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import { getTopCategories } from "actions";
import { getAllArts } from "actions";
import GridSlider from "components/GridSlider";
import { ImageDetailModal } from "components/ImageDetailModal";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { ASSETS_URL } from "constants";
import { HOST_URL } from "constants";
import React, { Component, useEffect, useRef, useState } from "react";
import { BsFillGridFill } from "react-icons/bs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { makeRandomSessionId } from "utils";
import { useSelector } from "react-redux";
import { mapAuthorities } from "utils";
import { PREMIUM_URL } from "constants";
import { getOriginalArts } from "actions";
import { ORIGINAL_URL, ORIGINAL_ATTRIBUTION } from "constants";
import { SPECIAL_ATTRIBUTION } from "constants";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function DigitalArt() {
  const [itemData, setItemData] = useState([]);
  const navigate = useNavigate();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  let [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let nameUrl = searchParams.get("name.contains");
  const observerTarget = useRef(null);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  let [xTotalCount, setXTotalCount] = useState(null);
  let [numberOfColumns, setNumberOfColumns] = useState(4);
  let PAGE_SIZE = 50;
  let [randomSessionId, setRandomSessionId] = useState(undefined);
  let [sort, setSortType] = useState("latest");
  let [selectedCategories, setSelectedCategories] = useState([]);
  let [categories, setCategories] = useState([]);
  let [selectedArt, setSelectedArt] = useState(undefined);
  let [isImageModalOpen, setIsImageModalOpen] = useState(false);
  let [goToPage, setGoToPage] = useState(page + 1);
  let authorities = useSelector(
    (state) => state.navigationReducer.user.authorities,
  );
  const location = useLocation();
  let originalAtribution = ORIGINAL_ATTRIBUTION;

  useEffect(() => {
    let route = location.pathname.split("/");
    let currentRoute = route[route.length - 1];
    console.log("redirecting", currentRoute);

    switch (currentRoute) {
      case "paintings":
      case "popular":
      case "special":
        setSort(currentRoute);
        fetchArts(currentRoute, undefined, 0);
        break;
      case "random":
        setSort(currentRoute);
        makeRandomSessionId()
          .then((sessionId) => {
            setRandomSessionId(sessionId);

            return sessionId;
          })
          .then((sessionId) => {
            setSort(currentRoute);
            fetchArts(currentRoute, sessionId, 0);
          });
        break;
      default:
        fetchArts("latest", undefined, 0);
        break;
    }
  }, []);

  let fetchArts = (sortType, randomId, currentPage) => {
    setIsLoading(true);
    setError(null);

    let filter = "";
    if (selectedCategories !== []) {
      selectedCategories.forEach((element) => {
        filter += element.name + ",";
      });
    }

    if (sortType === "paintings") {
      getOriginalArts(
        currentPage ? currentPage : page,
        PAGE_SIZE,
        filter,
        nameUrl,
      )
        .then((response) => {
          let data = response.data;

          data = data.map((entity) => {
            return {
              id: entity.id,
              name: entity.name,
              imageCompressedLocation: `${
                ORIGINAL_URL
              }/${entity.imageCompressedLocation[0]}`,
              resolution: entity.resolution,
              canvasHeight: entity.canvasHeight,
              canvasWidth: entity.canvasWidth,
              paintingDescription: entity.paintingDescription,
              price: entity.price,
              isSold: entity.isSold,
            };
          });

          setItemData((prevData) => [...data]);
          setXTotalCount((prevData) => response.headers["x-total-count"]);
        })
        .catch((e) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getAllArts(
        currentPage ? currentPage : page,
        PAGE_SIZE,
        filter,
        nameUrl,
        sortType === undefined ? sort : sortType,
        randomId === undefined ? randomSessionId : randomId,
      )
        .then((response) => {
          let data = response.data;

          data = data.map((entity) => {
            return {
              id: entity.id,
              name: entity.name,
              imageCompressedLocation: `${
                mapAuthorities(authorities) ? PREMIUM_URL : ASSETS_URL
              }/${entity.imageCompressedLocation}`,
              resolution: entity.resolution,
            };
          });

          setItemData((prevData) => [...data]);
          setXTotalCount((prevData) => response.headers["x-total-count"]);
        })
        .catch((e) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedArt === undefined) document.title = "Smaiart - Home";
  }, [selectedArt]);

  let handleGoToPage = () => {
    //
    setPage(goToPage - 1);
    window.scrollTo({ top: 0, left: 0 });
  };

  let keyPress = (e) => {
    if (e.keyCode == 13) {
      handleGoToPage();
      // put the login here
    }
  };

  let setSort = (sortType, randomId, page) => {
    navigate(`/${sortType}`);
    setSortType(sortType);
    setItemData([]);
    setPage(0);

    fetchArts(sortType, randomId, page);
  };

  let renderNumberOfColumns = () => {
    if (xs) return 2;
    if (sm) return 3;
    if (md) return 4;
    if (lg) return 6;

    return 6;
  };

  useEffect(() => {
    console.log(xs, sm, md, lg);
    setNumberOfColumns(renderNumberOfColumns());
  }, []);

  useEffect(() => {
    console.log("RESIZED");
    setNumberOfColumns(renderNumberOfColumns());
  }, [xs, sm, md, lg]);

  let addElementToCategories = (element) => {
    setSelectedCategories([...selectedCategories, element]);
    updateElementsForCategories([...selectedCategories, element]);
  };

  let removeElementFromCategories = (element) => {
    let updCategories = selectedCategories.filter(
      (category) => category != element,
    );
    setSelectedCategories(updCategories);
    updateElementsForCategories(updCategories);
  };

  let updateElementsForCategories = (cat) => {
    setItemData([]);
    setPage(0);
    fetchArts();
  };

  const handleArtClick = (art) => {
    setSelectedArt(art);
    setIsImageModalOpen(true);
  };

  useEffect(() => {
    fetchArts(sort, randomSessionId, page);
  }, [page]);

  useEffect(() => {
    getTopCategories()
      .then((response) => {
        let data = response.data;
        setCategories(data);
      })
      .catch((e) => {
        setError(error);
      });
  }, []);

  return (
    <MDBox px={2}>
      <ImageDetailModal
        isOpen={isImageModalOpen}
        onClose={() => {
          setIsImageModalOpen(false);
          setSelectedArt(undefined);
        }}
        image={selectedArt}
      />
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item>
          <Grid columnGap={1} container>
            {categories
              .slice(0, xs || sm ? 7 : categories.length - 1)
              .map((category, id) => {
                return (
                  <Grid item key={id} mt={1}>
                    {selectedCategories.includes(category) ? (
                      <Chip
                        className={"chip"}
                        label={category.name}
                        onDelete={() => {
                          removeElementFromCategories(category);
                        }}
                        onClick={() => {
                          removeElementFromCategories(category);
                        }}
                      />
                    ) : (
                      <Chip
                        className={"chip"}
                        label={category.name}
                        onClick={() => addElementToCategories(category)}
                        variant="outlined"
                      />
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>

        <div className="mt-5 mb-10 ml-1 flex flex-wrap flex-start w-full gap-5">
          <Typography
            variant={"h4"}
            className="sort"
            style={{ color: sort == "latest" ? "black" : "gray" }}
            onClick={() => {
              setSort("latest", undefined, page);
            }}
          >
            Latest
          </Typography>
          <Typography
            variant={"h4"}
            className="sort"
            style={{ color: "gray", fontWeight: "lighter" }}
          >
            |
          </Typography>
          <Typography
            variant={"h4"}
            style={{ color: sort == "popular" ? "black" : "gray" }}
            onClick={() => {
              setSort("popular", undefined, page);
            }}
            className="sort"
          >
            Popular
          </Typography>
          <Typography
            variant={"h4"}
            className="sort"
            style={{ color: "gray", fontWeight: "lighter" }}
          >
            |
          </Typography>
          <Typography
            variant={"h4"}
            style={{ color: sort == "special" ? "black" : "gray" }}
            onClick={() => {
              setSort("special", undefined, page);
            }}
            className="sort"
          >
            Special
          </Typography>
          <Typography
            variant={"h4"}
            className="sort"
            style={{ color: "gray", fontWeight: "lighter" }}
          >
            |
          </Typography>
          <Typography
            variant={"h4"}
            style={{ color: sort == "random" ? "black" : "gray" }}
            onClick={() => {
              makeRandomSessionId()
                .then((sessionId) => {
                  setRandomSessionId(sessionId);

                  return sessionId;
                })
                .then((sessionId) => {
                  setSort("random", sessionId, page);
                });
            }}
            className="sort"
          >
            Random
          </Typography>
          <Typography
            variant={"h4"}
            className="sort"
            style={{ color: "gray", fontWeight: "lighter" }}
          >
            |
          </Typography>
          <Typography
            variant={"h4"}
            style={{ color: sort == "paintings" ? "black" : "gray" }}
            onClick={() => {
              setSort("paintings", undefined, page);
            }}
            className="sort"
          >
            Paintings
          </Typography>
          {!sm && (
            <Grid
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                columnGap: 15,
              }}
              mr={3}
            >
              <BsFillGridFill />
              <GridSlider
                callback={(e) => {
                  setNumberOfColumns(e);
                }}
              />
            </Grid>
          )}
        </div>
        {/* <Box sx={{ width: 500, height: 450, overflowY: "scroll" }}> */}
        {nameUrl != null && nameUrl != "" && (
          <Box
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <Typography
              variant="h4"
              mt={3}
            >{`Search results for: '${nameUrl}'`}</Typography>
          </Box>
        )}
        {sort !== "paintings" ? (
          <Grid style={{ width: "100%" }} item>
            {sort === "special" && SPECIAL_ATTRIBUTION}
            <Masonry columns={numberOfColumns} gap={8}>
              {itemData.map((item) => (
                <a
                  href={`/digital-art/${item.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  target="_blank"
                >
                  <ImageListItem
                    style={{ width: "100%" }}
                    key={item.imageCompressedLocation}
                  >
                    <img
                      width={item.resolution.width}
                      height={item.resolution.height}
                      onClick={() => {
                        handleArtClick(item);
                      }}
                      className="image-tile"
                      loading="lazy"
                      style={{
                        backgroundColor: "#000",
                      }}
                      src={`${item.imageCompressedLocation}`}
                      srcSet={`${item.imageCompressedLocation}`}
                      alt={item.name}
                    />
                  </ImageListItem>
                </a>
              ))}
            </Masonry>
            <Grid
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              mt={10}
            >
              <Pagination
                count={Math.ceil(xTotalCount / 50)}
                page={page + 1}
                onChange={(e, page) => {
                  setPage(page - 1);
                  window.scrollTo({ top: 0, left: 0 });
                }}
              />
            </Grid>
            <Grid
              mt={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDTypography mr={1} variant="overline">
                Go to page:{" "}
              </MDTypography>
              <MDInput
                style={{ maxWidth: "100px" }}
                value={goToPage}
                onKeyDown={keyPress}
                onChange={(e) => {
                  if (
                    e.target.value >= 0 &&
                    e.target.value <= Math.ceil(xTotalCount / 50)
                  ) {
                    setGoToPage(e.target.value);
                  }
                }}
                type="number"
              ></MDInput>
              <PaginationItem
                onClick={() => handleGoToPage()}
                type="next"
                ml={1}
                color={"primary"}
              />
            </Grid>
            {<div ref={observerTarget}></div>}
            {/* </Box> */}
          </Grid>
        ) : (
          <Grid style={{ width: "100%" }} item>
            {originalAtribution}
            <Masonry columns={numberOfColumns} gap={8}>
              {itemData.map((item) => {
                return (
                  <a href={`/paintings/${item.id}`} target="_blank">
                    <ImageListItem
                      style={{ width: "100%" }}
                      key={item.imageCompressedLocation}
                    >
                      <img
                        width={item.resolution.width}
                        height={item.resolution.height}
                        className="image-tile"
                        loading="lazy"
                        style={{
                          backgroundColor: "#000",
                        }}
                        src={`${item.imageCompressedLocation}`}
                        srcSet={`${item.imageCompressedLocation}`}
                        alt={item.name}
                      />
                      {item.isSold && (
                        <p
                          className="absolute top-3 right-3 text-white rounded-lg p-2 text-sm font-semibold"
                          style={{ backgroundColor: "rgb(218, 59, 31)" }}
                        >
                          Sold
                        </p>
                      )}
                      <Grid
                        container
                        className="p-3"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div container className="flex-wrap">
                          <div
                            item
                            xs={10}
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                            className="mb-2"
                          >
                            <div className="flex content-left">
                              <p className="color-black font-semibold text-md text-left	">
                                {`${item.name}`}
                              </p>
                            </div>
                          </div>
                          <div item xs={2}>
                            <div className="flex font-semibold">
                              <p>{`$${item.price}`}</p>
                            </div>
                          </div>
                        </div>
                        <div className="flex content-left">
                          <p className="color-black text-sm color-gray-200 text-left">
                            {`${item.paintingDescription}, ${item.canvasWidth} W x ${item.canvasHeight} H cm`}
                          </p>
                        </div>
                      </Grid>
                    </ImageListItem>
                  </a>
                );
              })}
            </Masonry>
            <Grid
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              mt={10}
            >
              <Pagination
                count={Math.ceil(xTotalCount / 50)}
                page={page + 1}
                onChange={(e, page) => {
                  setPage(page - 1);
                  window.scrollTo({ top: 0, left: 0 });
                }}
              />
            </Grid>
            <Grid
              mt={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MDTypography mr={1} variant="overline">
                Go to page:{" "}
              </MDTypography>
              <MDInput
                style={{ maxWidth: "100px" }}
                value={goToPage}
                onKeyDown={keyPress}
                onChange={(e) => {
                  if (
                    e.target.value >= 0 &&
                    e.target.value <= Math.ceil(xTotalCount / 50)
                  ) {
                    setGoToPage(e.target.value);
                  }
                }}
                type="number"
              ></MDInput>
              <PaginationItem
                onClick={() => handleGoToPage()}
                type="next"
                ml={1}
                color={"primary"}
              />
            </Grid>
            {<div ref={observerTarget}></div>}
            {/* </Box> */}
          </Grid>
        )}
      </Grid>
    </MDBox>
  );
}

export default DigitalArt;
